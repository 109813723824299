<!--  -->
<template>
  <div
    class="left-container"
    v-if="acceptMsg"
    :style="{ display: bp }"
    :class="smasllPhone"
  >
    <!-- 媒体查询时 -->
    <div class="sidebar">
      <div
        v-show="acceptMsg"
        :class="this.acceptScreen <= 768 ? 'mask' : ''"
        @click="maskDisplay()"
      ></div>
      <div class="sidebar-menu">
        <header class="logo-env">
          <a href="/">
            <img src="../assets/imgs/text.png" alt="" />
          </a>
        </header>
        <div
          class="container-fluid"
          v-for="(item, index) in data"
          :key="item.id"
          to="/"
        >
          <ul class="nav flex-column" @click.prevent="listDown(index)">
            <li class="nav-item">
              <router-link to="/">
                <a class="nav-link" href="#">
                  <i class="iconfont" :class="item.iconClass"> </i>
                  <span ref="span1">{{ item.bigElassify }}</span>
                  <i
                    class="iconfont"
                    :class="[
                      { tDown: dp == index },
                      item.inu ? '' : 'icon-jiantouyou',
                    ]"
                    ref="ico1"
                  ></i>
                </a>
              </router-link>
              <ul
                v-for="list in item.children"
                :key="list.index"
                v-show="dp === index"
                class="listDisplay"
              >
                <li @click.stop="" @click="onHandlerClick(list)">
                  <span
                    class="nav-link active"
                    :href="'#' + list.smallElassify"
                    ref="leftBox"
                  >
                    <i class="iconfont icon-youjiantou dif"></i>
                    <span>{{ list.smallElassify }}</span>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- 分类显示的ul -->
        <div class="container-fluid">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link" href="#">
                <i class="iconfont icon-biaoqian"> </i>
                <span ref="span1">
                  <router-link to="/about">关于本站</router-link>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--  点击控制-->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

// import { type } from "os";
import bus from "./EventBus";
export default {
  name: "MyLeft",
  props: {
    data: {
      default: [],
      type: Array,
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      dp: -1,
      //接受的数据
      acceptMsg: true,
      acceptScreen: 0,
      bp: "",
      smasllPhone: "",
      mask: false,
    };
  },

  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 点击展开对应的ul
    listDown(index) {
      if (this.dp == index) {
        this.dp = -1;
      } else {
        this.dp = index;
      }
    },
    //遮罩层的显示和隐藏
    maskDisplay() {
      this.acceptMsg = !this.acceptMsg;
      bus.$emit("back", this.acceptMsg);
    },
    onHandlerClick(e) {
      const val = { val: "#" + e.smallElassify, index: 0 };
      bus.$emit("maoDian", val);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    bus.$on("share", (e) => {
      this.acceptMsg = e.val;
      this.acceptScreen = e.screenWidth;
      //大屏幕点击变化
      if (this.acceptScreen <= 768) {
        // this.acceptMsg = !this.acceptMsg;
        this.bp = "block";
        this.smasllPhone = "smasllPhone";
      }
    });
    this.acceptScreen = document.body.clientWidth;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.left-container {
  display: table-cell;
  width: 250px;
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: inherit;
    height: 100%;
    // background-color: #ccc;
    // background-image:
    // background: linear-gradient(blue, pink);
    background-image: linear-gradient(#fdcd07, #d6d7e4);
    .mask {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: #ccc;
      opacity: 0.3;
      z-index: -1;
      filter: blur(5px);
    }
    .sidebar-menu {
      height: 100%;
      .logo-env {
        width: 100%;
        padding: auto;
        > a > img {
          width: 100%;
          padding: 20px;
        }
      }
      .listDisplay {
        // display: none;
        transition: all 1s;
        animation: flow 0.6s ease;
      }
      .nav-link {
        // text-align: center;
        font-size: 14px;
        color: #111;
        padding: 10px 10px 10px 30px;
        border-bottom: 1px solid rgb(200 164 164 / 39%);
        > i {
          display: inline-block;
          margin-right: 12px;
        }
        .icon-jiantouyou {
          float: right;
          font-size: 12px;
          margin-top: 2px;
          animation: backZ 0.5s linear;
        }
        .tDown {
          animation: turnZ 0.5s linear;
          transform: rotate(90deg);
        }
      }
      .dif {
        margin-right: 5px !important;
        margin-left: 20px;
      }
      .active {
        // padding-left: 45px;
        cursor: pointer;
        // margin-left: 15px;
      }
    }
  }
}
.smasllPhone {
  width: 250px;
  position: fixed;
  z-index: 12;
}
// 媒体查询
@media screen and (max-width: 480px) {
}

/* 小屏幕（平板，大于等于 768px） */

/* 中等屏幕（桌面显示器，大于等于 992px） */

/* 大屏幕（大桌面显示器，大于等于 1200px） */
@media (max-width: 1200px) {
}
// @media screen and (max-width: 992px) {
//   .left-container {
//     width: 80px;
//     .sidebar {
//       .sidebar-menu {
//         .nav-link {
//           padding: 15px 10px;
//           text-align: center;
//           // text-align: center;
//           span {
//             display: none;
//           }
//           > i {
//             margin: 0 auto;
//             font-size: 20px;
//           }
//           .icon-jiantouyou {
//             display: none;
//           }
//         }
//       }
//     }
//   }
// }
@media (max-width: 768px) {
  .left-container {
    // width: 0;
    display: none;

    .sidebar {
      // display: none;
    }
  }
}
</style>