<!--  -->
<template>
  <div class="Right-container">
    <div class="right-nav">
      <!-- 分类 -->
      <ul class="left-links">
        <li class="fenlei">
          <a @click="sendMsg()">
            <i class="iconfont icon-fenlei"> </i>
          </a>
        </li>
      </ul>
      <!-- search 搜索框-->
      <ul class="right-links">
        <li class="search">
          <a href="https://cn.bing.com/" target="_blank">
            <i class="iconfont icon-sousuo"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- <MyList :items="data"></MyList> -->
    <router-view :items="data"></router-view>
    <div class="foot">
      <br />
      <div class="br"></div>
      <div class="footer-text">
        © 2021-2022
        <a href="../cn/about.html"><strong>飞鸟杂货铺</strong></a> copyright
        <a href="#" target="_blank"><strong>冷风blows</strong></a>
        <!-- Start of CuterCounter Code -->
        <div class="count">
          <span>
            <a href="https://github.com/blowsman/NewWebNav" target="_blank">
              <i class="iconfont icon-github" style="font-size: 14px"></i>
            </a>

            您是本站第</span
          >
          <img
            src="https://www.cutercounter.com/hits.php?id=hxfnokq&nd=6&style=1"
            border="0"
            alt="免费计数器"
            style="padding: 0 0 2px 3px"
          />
          <span> 位访客 </span>
        </div>
        <!-- End of CuterCounter Code -->
        <a
          href="javascript:"
          id="go-to-up"
          class=""
          rel="go-top"
          style="display: inline"
        >
          <i class="iconfont icon-to-up"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import MyList from "./List.vue";
import bus from "./EventBus";
import About from "../about/about.vue";
export default {
  name: "MyRight",
  props: {
    data: {
      default: [],
      type: Array,
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {
    MyList,
  },
  data() {
    //这里存放数据
    return {
      msg: true,
      clientWidth: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    sendMsg() {
      this.msg = !this.msg;
      console.log(this.msg);

      const obj = { val: this.msg, screenWidth: this.clientWidth };
      bus.$emit("share", obj);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    bus.$on("back", (e) => {
      this.msg = e;
      console.log(this.msg);
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 在DOM渲染数据时，设置下区域高度为浏览器可视区域高度．
    this.clientWidth = document.body.clientWidth;
    // 监听window的resize事件．在浏览器窗口变化时再设置下区域高度．
    const _this = this;
    window.onresize = function temp() {
      _this.clientWidth = document.body.clientWidth;
    };
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.Right-container {
  display: table-cell;
  // padding-left: 250px;
  .right-nav {
    // display: flex;
    // padding-left: 250px;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #f5f3f3;
    // margin-bottom: 10px;
    > ul {
      // flex: 1;
      height: 70px;
      display: inline-block;
      margin: 0;
      padding: 20px 20px;
    }
    .left-links {
      // align-self: flex-start;
      .fenlei {
        height: 100%;
        cursor: pointer;
        // width: 50px;
        > a {
          // display: inline-block;
          // padding: 19px 20px;
          .icon-fenlei {
            font-size: 22px;
          }
        }
      }
    }
    .right-links {
      float: right;
      .search {
        height: 100%;
        // width: 50px;
        > a {
          display: inline-block;
          // padding: 19px 20px;
          .icon-sousuo {
            font-size: 22px;
          }
        }
      }
    }
  }
  .foot {
    .br {
      height: 1px;
      background-color: rgb(193, 182, 182);
      border-radius: 1px;
      margin: 0 20px;
    }
    .footer-text {
      padding: 5px 0 20px 20px;
    }
    .count {
      display: inline-block;
      float: right;
      margin-right: 20px;
      font-weight: 600;
    }
  }
}
// 媒体查询
@media screen and (max-width: 992px) {
  .Right-container {
    // padding-left: 80px;
  }
}
@media screen and (max-width: 768px) {
  .Right-container {
    padding-left: 0;
    .foot {
      .count {
        display: block;
        text-align: center;
        margin: 0;
        float: none;
        margin-left: -20px;
      }
    }
  }
}
</style>