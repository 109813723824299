<!--  -->
<template>
  <!-- 右侧下方 -->
  <div class="rightRecommend" ref="sda">
    <div v-for="item in items" :key="item.id">
      <div
        class="bro"
        v-for="(list, index) in item.children"
        :key="list.id"
        ref="demoRight"
        :id="list.smallElassify"
        :class="list.more ? 'bro2' : ''"
      >
        <div>
          <div>
            <h4 class="text-gray" ref="leftBox">
              <i class="iconfont icon-zu"></i>{{ list.smallElassify }}
              <span
                class="moreList"
                @click="more(list.smallElassify)"
                v-show="list.more"
              >
                more+
              </span>
            </h4>
          </div>
          <div class="row" ref="row">
            <div
              class="col-sm-3"
              v-for="(midList, index) in list.web"
              :key="midList.id"
              :href="midList.webPlace"
            >
              <div
                class="xe-widget box2"
                targe="_blank"
                href="list"
                @click="place(midList.webPlace)"
              >
                <div class="xe-comment-entry">
                  <a class="xe-user-img">
                    <img src="" alt="" />
                    <img
                      :src="
                        'https://api.iowen.cn/favicon/' +
                        midList.webPlace.slice(
                          midList.webPlace.indexOf('/') + 2
                        ) +
                        '.png'
                      "
                      width="40"
                      class="lozad img-circle"
                  /></a>
                  <div class="xe-comment">
                    <a href="#" class="xe-user-name overflowClip_1"
                      ><strong>{{ midList.webName }}</strong></a
                    >
                    <p class="overflowClip_2">
                      {{ midList.describe }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import bus from "./EventBus";

export default {
  name: "MyList",
  props: {
    items: {
      default: [],
      type: Array,
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      defaultImg: "",
      midListNum: 0,
      moreDispaly: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    place(wp) {
      var webPlace = wp;
      window.open(webPlace, "_blank");
    },
    more(e) {
      const getId = "#" + e;
      // this.$el.querySelector(getId).classList.add("bro2");

      // "more": true  在data.josn 中加就会显示出来
      // more+按钮显示和切换
      if (this.moreDispaly) {
        this.$el.querySelector(getId).style.height = "100%";
        this.moreDispaly = !this.moreDispaly;
        // this.moreDispaly = "100%";
      } else {
        this.$el.querySelector(getId).style.height = "261px";
        // this.moreDispaly = "256px";
        this.moreDispaly = !this.moreDispaly;
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    bus.$on("maoDian", (e) => {
      //大屏幕点击变化
      // :style="{ display: bp }"
      // this.mask = !this.mask;
      this.$el.querySelector(e.val).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.$refs.row.forEach( index = > {
    //   this.midListNum = this.$refs.row[index].children.length;
    // });
    // this.midListNum = this.$refs.row[0].children.length;
    // console.log(this.$refs.row[0].children.length);
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.rightRecommend {
  padding: 20px;
  padding-bottom: 0;
  .bro {
    padding: 10px 0;
    // height: 265px;
    overflow: hidden;
  }
  .bro2 {
    height: 261px;
  }
  .text-gray {
    font-size: 16px;
    margin-bottom: 0;
    .icon-zu {
      // vertical-align: top;
      // font-size: 14px;
      vertical-align: middle;
      padding: 0 5px;
    }
    .moreList {
      float: right;
      color: #c3c2c2;
      cursor: pointer;
    }
  }
  .box2 {
    // height: 20px;
    // background-color: #111;
    // float: left;
    // padding: 20px;
    height: 86px;
    cursor: pointer;
    border-radius: 4px;
    padding: 0 16px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e4ecf3;
    margin: 20px 0 0 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    .xe-widget {
      position: relative;
      background: #fff;
      margin-bottom: 0px px;
      padding: 15px;
    }
    .xe-comment-entry {
      // margin-left: 50px;
      position: relative;
      left: 0;
      top: 17%;
      overflow: hidden;
      .img-circle {
        float: left;
        display: block;
        margin-right: 10px;
        padding: 7px 0;
        border-radius: 50%;
      }
      .xe-comment {
        display: inline-block;
        position: absolute;
        top: 0;
        // left: 0;
        > p {
          margin-bottom: 0;
          margin-right: 5px;
        }
        .overflowClip_1 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .overflowClip_2 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
.box2:hover {
  transform: translateY(-6px);
  // -webkit-transform: translateY(-6px);
  // -moz-transform: translateY(-6px);
  box-shadow: 0 26px 40px -24px rgb(0 36 100 / 30%);
  -webkit-box-shadow: 0 26px 40px -24px rgb(0 36 100 / 30%);
  -moz-box-shadow: 0 26px 40px -24px rgba(0, 36, 100, 0.3);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
//媒体查询
@media screen and (max-width: 768px) {
  .Right-container {
    .col-sm-3 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>