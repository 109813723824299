import Vue from 'vue'
import VueRouter from 'vue-router'
import List from '../components/List'
import About from '../about/about'


Vue.use(VueRouter)
const router = new VueRouter({
  routes: [{
      path: '/',
      redirect: '/index'
    },
    { path: '/index', component: List },
    { path: '/about', component: About },
  ]
})
export default router