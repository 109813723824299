<template>
  <div id="app">
    <!-- 左侧导航栏 -->
    <MyLeft :data="items"></MyLeft>
    <MyRight :data="items"> </MyRight>
  </div>
</template>

<script>
import MyLeft from "./components/Left.vue";
import MyRight from "./components/Right.vue";
import itemDatas from "./assets/data.json";

export default {
  name: "App",
  components: {
    MyLeft,
    MyRight,
  },
  data() {
    return {
      items: itemDatas,
    };
  },
  created() {
    // console.log(this.items);
  },
};
</script>
<style lang="less">
@import "./assets/css/bootstrap.css";
@import "./assets/css/base.css";
@import "./assets/fonts/iconfont.css";
@import "./assets/css/animate.css";
#app {
  display: table;
  width: 100%;
  // background-color: #ccc;
}
</style>
