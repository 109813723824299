<!--  -->
<template>
  <div class="about-container">
    <div class="prep">
      <div class="container">
        <h4><i class="iconfont icon-tag2 tag"></i> 关于本站</h4>
        <span class="motto">------取悦自己，热爱生活</span>
        <div class="br1"></div>
        <div class="text-sm">
          <h5>
            导航狮（冷风杂货铺）-----是收集各类小工具的导航网址，偏执于高质量资源和技巧分享
          </h5>
          <p>
            <li class="text">
              1、站内内容均由用户从互联网收集而来，仅供用于学习和交流，请遵循相关法律法规使用！
            </li>
            <li class="text">
              2、本站涉及的所有网站皆为外站，其展示内容并不代表本站观点！
            </li>
            <li class="text">
              3、如果部分资源侵犯了你的合法权益，请通过下方邮箱及时联系我们！
            </li>
          </p>
        </div>
        <h5>
          <i class="iconfont icon-rocket rocket"></i
          >除了这里，你可能会在这些地方见到我
        </h5>
        <div class="footer-social">
          <a
            class="rounded-circle bg-light"
            href="https://note.youdao.com/s/1arown5l"
            target="_blank"
            title="微信"
          >
            <i class="iconfont icon-weixin"></i>
          </a>
          <a
            class="rounded-circle bg-light"
            href="https://www.zhihu.com/people/hua-li-hu-shao-de-hu-xian-sheng"
            target="_blank"
            title="知乎"
          >
            <i class="iconfont icon-zhihu"></i>
          </a>
          <a
            class="rounded-circle bg-light"
            href="tencent://AddContact/?fromId=50&fromSubId=1&subcmd=all&uin=2270321830"
            target="_blank"
            title="QQ"
          >
            <i class="iconfont icon-qq"></i>
          </a>
          <a
            class="rounded-circle bg-light"
            href="https://space.bilibili.com/385617670"
            target="_blank"
            title="哔哩哔哩"
          >
            <i class="iconfont icon-bilibili-line"></i>
          </a>
          <a
            class="rounded-circle bg-light"
            href="mailto:1839946503@qq.com?subject=邮件标题&body=邮件内容"
            target="_blank"
            title="Email邮箱"
          >
            <i class="iconfont icon-email"></i>
          </a>
        </div>
        <div class="wechat">
          <img src="../assets/imgs/gongzhong.jpg" alt="" class="wechat-img" />
          <p>微信公众号</p>
        </div>
      </div>
    </div>
    <!-- <p>about组件</p> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "MyAbout",
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.about-container {
  display: flex;
  // background-color: #8c8c8c2b;
  // #ededc21f
  width: 80%;
  margin: 20px auto;
  .prep {
    flex: 1;
    background-color: #f4f1f1;
    border-radius: 20px;
    // border: 1px solid #ccc;
    box-shadow: 0px 0px 20px -5px rgba(158, 158, 158, 0.2);
    transition: background-color 0.3s;
    .container {
      // height: 500px;
      margin: 20px 0;
      .motto {
        display: inline-block;
        text-align: right;
        width: 100%;
      }
      .tag {
        font-size: 1.5rem;
      }
      .rocket {
        font-size: 1rem;
        padding: 0 4px;
      }
      .br1 {
        height: 1px;
        background-color: rgb(193, 182, 182);
        margin: 12px 0;
        // border-radius: 1px;
        // margin: 0 20px;
      }
      .text-sm {
        border-left: 5px solid #8e8787;
        padding-left: 10px;
        margin-bottom: 10px;
        > p:first-letter {
          font-size: 2em;
          padding: 0.1em;
          color: #000000;
          vertical-align: middle;
        }
        .text {
          // text-align: justify;
          text-indent: 25px;
          line-height: 1.8;
          letter-spacing: 1px;
          // word-spacing: -7px;
        }
      }
      .footer-social > a {
        position: relative;
        display: inline-block;
        margin: 5px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
      }

      .wechat {
        position: relative;
        padding-top: 12px;
        text-align: center;
        > p {
          padding-top: 10px;
        }
        .wechat-img {
          width: 100px;
          // position: absolute;
          // top: 0;
          // right: 0px;
        }
      }
    }
  }
}
</style>